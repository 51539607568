import {useParams} from "react-router";
import useWebRTC from "./hooks/useWebRTC";
import {useEffect, useRef} from "react";
import axios from "axios";
import Pusher from "pusher-js";
import freeice from "freeice";
// import * as stream from "stream";

export default function Room() {
    // const API = 'http://localhost/api';
    const API = 'https://test-call-api.fiery-octopus.by/api'; // local
    const {id: roomID} = useParams();
    // const {clients, provideMediaRef} = useWebRTC(roomID);
    // const [clients] = useWebRTC(0);

    // rtc
    const pusherRef = useRef(); // <Pusher>
    const channelRef = useRef(); //<PresenceChannel>

    const userStream = useRef();
    const userVideo = useRef(null); // <HTMLVideoElement>
    const partnerVideo = useRef(null); // <HTMLVideoElement>
    const localRTCConnection = useRef(null); // <RTCPeerConnection | null>
    const remoteRTCConnection = useRef(null); // <RTCPeerConnection | null>

    console.log("RoomId: ", roomID);
    // console.log("clients: ", clients);

    useEffect(() => {

        try {
            Pusher.logToConsole = true;
            pusherRef.current = new Pusher('livepost_key', {
                cluster: 'mt1',
                wsHost: 'test-call-api.fiery-octopus.by',
                // wsHost: 'localhost',
                wssHost: 'test-call-api.fiery-octopus.by',
                // wssHost: 'localhost',
                wsPort: 6001,
                // wsPort: 6001, // local port
                wssPort: 6001,
                forceTLS: true,
                // forceTLS: false, // local
                disableStats: true,
                // disableStats: false, // local
                enabledTransports: ['ws', 'wss'],
                // enabledTransports: ['ws'], // local only ws
                // userAuthentication: {
                //     endpoint: "/laravel-websockets/auth",
                // },
                // authEndpoint: `https://test-call-api.fiery-octopus.by/laravel-websockets/auth`,
                // auth: {
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YWIwODg5Ni02MmE0LTQ4MTgtODUwZS1hM2NjNjMyNzIyMTYiLCJqdGkiOiI1ZDNjOTZiZDEwZTg5ZmY4NjFkZDBiNDM4MTEyYzJlZTlmNGUzMzYwNTAzOGJhMjhiODkwMWUxYzI4NzUzN2I2ZWFhMTljYzg4ZWFlNWI2YSIsImlhdCI6MTcwMTQ0MTg1My44Mjg3MywibmJmIjoxNzAxNDQxODUzLjgyODczMiwiZXhwIjoxNzMzMDY0MjUzLjgxODY5Miwic3ViIjoiMSIsInNjb3BlcyI6W119.TwxZKWPyhDSM9ILkinXdwXOiWgw6BSkgvMHt0NqpQMqpa_rhNyfiCqLyQqcHA06pIUHsFQrBrMmOxXMfrJmBh_8e-N2zU8-4IhrRjUism3xFs4yALc0wGs1UDC4QgkjynYMFH2WPcOeat_6O1P9Dk9ZGiMrzeqTK3Q3WMs_tG1a20TwEQOWzkVdbRpn2GTMKCegiw2I_l26-U7KPac9Qakl_hNOtfCPJRxZ3WD6cneb1BqxtjLGG-JPzcC4wtUjK-qQgkx91m4tY_RchbfWaBQcqQZlnCzvcNSTQRMcgDkpoHac1YntPetX-nTQqREvurw8AHjbnxBcU1XjK_o6pKsbWiF5R7QFIhN3IibWnI_zIJWj12LzX6T_kp8fNeL6zMVncB0DDkBEmoD1D4dKIESMv2XcPTw3ifVVOv8GVwxdyCy5BQx-Y5zsYN9oyfmfd6pRZOYtAea7NRCy7oG3vBq3VoeQfMHm15zlk_01iNC69B4SPA_pAvTMkOx0nuv5U8HHWyZkrQPKMvqp9Evoi0j--RWlkNhSKyC4ETQ16KBdrlLXZpqalCt5K73Zkc9THbQ69FVPgCTnEQmkHk4lNZZecKqdraWKYBI-2GrDxii2lZE-kldTANGQGC6bQtj9jKX7d-5pzDKDBi0zvgBw4S4oCvUndELPJg31sFVIqd6c',
                //         // 'X-CSRF-Token': "CSRF_TOKEN"
                //     }
                // },
                // enabledTransports: ['ws']
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            axios
                                .post('https://test-call-api.fiery-octopus.by/api/users/login', {
                                    socket_id: socketId,
                                    channel_name: channel.name,
                                    key: 'livepost_key',
                                    "email": "admin@admin.admin",
                                    "password": "password",
                                    "name": "Admin"
                                })
                                .then((response) => {
                                    callback(false, response.data)
                                })
                                .catch((error) => {
                                    callback(true, error)
                                })
                        },
                    }
                },
            });

            channelRef.current = pusherRef.current.subscribe(
                `video-channel` // ${roomName}`
            );

        } catch (e) {
            console.error("test: ", e)
        }

        // set video on element
        // handleRoomJoined();

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: { width: 200, height: 200 },
            }).then((stream) => {
            userVideo.current.srcObject = stream;

            const videoTrack = stream.getVideoTracks()[0];

            // local caller
            localRTCConnection.current = new RTCPeerConnection(freeice());

            // ice-candidate event
            localRTCConnection.current.onicecandidate = (event) => {
                if (event.candidate) {
                    console.log("local candidate yes!");
                    axios.post(`${API}/test-client-candidate`, {"candidate": JSON.stringify(event.candidate)})
                        .then(r => {console.log("ice yes!")});
                }
            }

            channelRef.current.bind('client-remote-candidate', (res) => {
                console.log("client candidate: ", JSON.parse(res.candidate));
                localRTCConnection.current.addIceCandidate(new RTCIceCandidate(res.candidate));
            });

            channelRef.current.bind('client-answer', (res) => {
                console.log("client answer: ", JSON.parse(res.description));

                localRTCConnection.current.setRemoteDescription(
                    new RTCSessionDescription(JSON.parse(res.description))
                );

            });

            localRTCConnection.current.addTrack(videoTrack, stream);

            localRTCConnection.current.createOffer().then(description => {
                localRTCConnection.current.setLocalDescription(description);
                // send event to next user
                axios.post(`${API}/test-client-offer`, {"description": JSON.stringify(description)})
                    .then(r => {
                        console.log("create offer");
                    });
            });
            });


        // remote caller
        remoteRTCConnection.current = new RTCPeerConnection(freeice());
        channelRef.current.bind('client-offer', (res) => {
            console.log("client offer: ", JSON.parse(res.description).type !== undefined);
            // if (JSON.parse(res.description).type !== undefined && JSON.parse(res.description).type === "offer") {
            if (JSON.parse(res.description).type !== undefined) {
                if (JSON.parse(res.description).type === "offer") {
                    remoteRTCConnection.current.setRemoteDescription(JSON.parse(res.description));

                    // ice-candidate event for remote
                    remoteRTCConnection.current.onicecandidate = (event) => {
                        if (event.candidate) {
                            console.log("remote candidate yes!");
                            axios.post(`${API}/test-client-remote-candidate`, {"candidate": JSON.stringify(event.candidate)})
                                .then(r => {
                                    console.log("ice yes!")
                                });
                        }
                    }

                    remoteRTCConnection.current.ontrack = (event) => {
                        console.log("ontrack");
                        if (event.streams) {
                            partnerVideo.current.srcObject = event.streams[0];
                        }
                    }

                    channelRef.current.bind('client-candidate', (res) => {
                        console.log("get from local client candidate: ", JSON.parse(res.candidate));
                        remoteRTCConnection.current.addIceCandidate(new RTCIceCandidate(JSON.parse(res.candidate)));
                    });

                    remoteRTCConnection.current.createAnswer().then(description => {
                        remoteRTCConnection.current.setLocalDescription(description);

                        axios.post(`${API}/test-client-answer`, {"description": JSON.stringify(description)}).then(r => {
                            console.log("create answer");
                        });
                    });
                }
            }
        });


    }, []);


    const handleRoomJoined = () => {
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: { width: 200, height: 200 },
            })
            .then((stream) => {
                /* use the stream */
                userStream.current = stream;
                userVideo.current.srcObject = stream;
                userVideo.current.onloadedmetadata = () => {
                    userVideo.current.play();
                }
                // if (!host.current) {
                    // the 2nd peer joining will tell to host they are ready
                    // console.log('triggering client ready: ', channelRef.current);
                    // channelRef.current.bind('.video-channel', () => {
                    //    alert('');
                    // });
                    // channelRef.current.trigger('client-ready', {});
                    // axios.post('http://localhost/api/test-user-ready', {
                    //
                    // });
                // }
            })
            .catch((err) => {
                /* handle the error */
                console.log(err);
            })
    }

    return (
    <>
    <h1>Room</h1>
        User Video
        <video
            ref={userVideo}
            autoPlay={true}
            playsInline={true}
            muted={true}
        />
        Partner Video
        <video
            ref={partnerVideo}
            autoPlay={true}
            playsInline={true}
        />
        {/*{*/}
        {/*    clients.map(clientID => {*/}
        {/*        return (*/}
        {/*            <div key={clientID}>*/}
        {/*                <video*/}
        {/*                    // ref={instance => {*/}
        {/*                    //     provideMediaRef(clientID, instance)*/}
        {/*                    // }}*/}
        {/*                    autoPlay={true}*/}
        {/*                    playsInline={true}*/}
        {/*                    muted={clientID === "LOCAL_VIDEO"}*/}
        {/*                />*/}
        {/*            </div>*/}
        {/*        )*/}
        {/*    })*/}
        {/*}*/}
    </>
    );
}
