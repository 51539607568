import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import Room from "./Room";
import {RouterProvider} from "react-router";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Room />,
        children: [],
    },
    {
        path: "room/242d65d3-401e-46bd-bd3f-0fb5161c3676",
        element: <Room />,
    }
]);

ReactDOM.createRoot(document.getElementById('root'))
    .render(
    <RouterProvider router={router} />
);
